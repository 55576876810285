import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  // tslint:disable-next-line:no-inferrable-types
  public sliderPath: string = 'assets/images/slider/home/';
  constructor() { }

  public slider = [
    {id: 1, image: 'slider-1.jpg', alt: "Craft your own blade", title: '', subtitle: '', button: {label: 'Start Now', link: 'blades/12', class: 'btn1' }},
    {id: 2, image: 'slider-2.jpg', alt: "Modern day armour", title: '', subtitle: '', button: {label: 'View Range', link: 'categories/11', class: 'btn2' }},
    {id: 3, image: 'slider-3.jpg', alt: "Carry your confidence", title: '', subtitle: '', button: {label: 'View Range', link: 'products/7', class: 'btn3' }}
  ];

  ngOnInit() {  }

  // Slick slider config
  // tslint:disable-next-line:member-ordering
  public sliderConfig: any = {
    autoplay: true,
    autoplaySpeed: 3000
  };

}
