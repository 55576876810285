import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  public cloneDeep(obj){
      return JSON.parse(JSON.stringify(obj));
  }

  public fnToDate(date){
    let newDate:any = new Date(date);
    let a = newDate != 'Invalid Date'?newDate:null;
    return a;
  }

  public fnDateToString(date){
    if(date=="0000-00-00 00:00:00")
    return null;
    let newDate:any = new Date(date).toISOString();
    return newDate;
  }

  public fnDateToString2(date){
    if(date=="0000-00-00 00:00:00")
    return null;
    let newDate:any = new Date(date);
    return newDate;
  }

  public fnGetKeys(obj:any={}){
    return Object.keys(obj);
  }
  
  public capitalize(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  // filter
  public filter(items: any[], field : string, value : string): any[] {
    if (!items) return [];
    if (!value || value.length == 0) return items;
    return items.filter(it => 
    it[field].toLowerCase().indexOf(value.toLowerCase()) !=-1);
  }
}