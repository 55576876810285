import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-single-category',
  templateUrl: './single-category.component.html',
  styleUrls: ['./single-category.component.scss']
})
export class SingleCategoryComponent implements OnInit {
  @Input() category:any;

  public showMyElement: any;
  constructor(
    private commonService: CommonService,
    private router: Router
  ) { }

  fnCategory(id): void{
    this.router.navigateByUrl('/home/category/' + id);
  }

  ngOnInit() {
  }

}
