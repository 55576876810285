import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Product } from './../../shared/classes/product';
import { CartItem } from '../../shared/classes/cart-item';
import { ProductsService } from '../../shared/services/products.service';
import { CartService } from '../../shared/services/cart.service';
import { Observable, of } from 'rxjs';
import { CommonService, DataTablesResponse } from 'src/app/shared/services/common.service';
import { CurrencyPipe } from '@angular/common';
import { ScrollTopService } from 'src/app/shared/services/scrolltotop.service';
import { ZoomPopupComponent } from '../product/product-details/armour-product/zoom-popup/zoom-popup.component';
import { UtilityService } from 'src/app/shared/services/UtilityService';
import { HttpClient } from '@angular/common/http';
declare var $: any;
 
@Component({
  selector: 'app-custom-product',
  styleUrls: ['./custom-product.component.scss'],
  templateUrl: './custom-product.component.html'
})
export class CustomProductComponent implements OnInit { 

  @ViewChild(ZoomPopupComponent) zoomPopup:ZoomPopupComponent;
  
  public product            :   Product = {};
  public products           :   Product[] = [];
  public cartItems          :   Observable<CartItem[]> = of([]);
  public shoppingCartItems  :   CartItem[] = [];
  public counter            :   number = 1; 
  public selectedSize       :   any = '';
  public optionId: any = "";
  public boptionId: any = "";
  
  public productPath = this.common.productImagePath;

  public option:any = {};

  public selectedProduct:any = {};
  public whatYouGet = false;

  public myProduct: any = { id: 12, title: "Blade", steps: [] };
  public step: any = {};

  public steps:any = [];

  // Get Product By Id
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public toastrService: ToastrService,
    public productsService: ProductsService,
    public cartService: CartService,
    public cp: CurrencyPipe,
    private http: HttpClient,
    public common: CommonService,
    private scrollToTop: ScrollTopService,
    public utils: UtilityService,
    public commonService: CommonService
  ) {
      this.route.params.subscribe(params => {
        const id = +params['id'];
        if(id !== 12) {
          router.navigate(['/error']);
        }
        this.productsService.getProduct(id).subscribe(product => this.product = product);
      });
  }

  public slideRightConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    fade: true
  }

  public slideRightNavConfig = {
    // vertical: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    asNavFor: '.product-slick',
    arrows: false,
    dots: false,
    focusOnSelect: true
  }

  public slideStep1Config = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    fade: true
  }

  public slideSlide1NavConfig = {
    // vertical: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    dots: false,
    focusOnSelect: true
  }

  public slideConfigObj: any = {
    focusOnSelect: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    fade: true,      
    asNavFor: '.slider-nav'
  };

  public slideNavConfigObj: any = {
    vertical: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    horizontalSwipping: false,
    arrows: false,
    infinite: false,
    dots: false,
    centerMode: false,
    focusOnSelect: true,
    asNavFor: '.product-slick'
  };

  public slideConfig: any = [];
  public slideNavConfig: any = [];

  public optionLoaded: boolean = false;

  fnReset(step_id=this.steps[0].id, option_id=this.steps[0].options[0].id): void {
    // console.log(this.steps, this.option)
    // this.selectedProduct = this.utils.cloneDeep(this.myProduct);
    this.myProduct.steps = this.utils.cloneDeep(this.steps);
    this.fnPickThis(step_id,option_id,1,false);
    this.whatYouGet = false;
  }

  fnSelectOption(step_id, opt_id, pickThis=false) { 
    this.slideConfig = [];
    this.slideNavConfig = [];
    this.optionLoaded = false;

    setTimeout(() => {
      this.optionLoaded = true;
    }, 200);
    this.myProduct.steps = this.myProduct.steps.map((step)=>{
      // step.selected = false;
      if(step_id === step.id){
        step.options = step.options.map((opt)=>{          
          if(opt_id === opt.id){
            // if (step.id === 2 && opt.id === 3) {
            //   this.myProduct.steps[2].options[0].disabled = true;
            // } else {
            //   this.myProduct.steps[2].options[0].disabled = false;
            // }
            this.myProduct.steps[2].options[0].disabled = false;
            opt.selected = true;
            this.option = opt;

            if(this.option.pictures && this.option.pictures[0]){
              for(let i=0;i<this.option.pictures.length;i++){
                $('button.slick-prev').click();
              }
            }
            
            // console.log(opt);
          }else{opt.selected = false;}

          if(step_id == 1 || step_id == 2) {
            this.slideConfig[opt.id] = {
              focusOnSelect: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: false,
              arrows: true,
              fade: true,      
              asNavFor: '.slider-nav-' + opt.id
            };

            this.slideNavConfig[opt.id] = {
              vertical: false,
              slidesToShow: 3,
              slidesToScroll: 1,
              horizontalSwipping: false,
              arrows: false,
              infinite: false,
              dots: false,
              centerMode: false,
              focusOnSelect: true,
              asNavFor: '.product-slick-' + opt.id
            };
          }

          return opt;
        });        
        if(pickThis){
          step.selected = true;
          step.option = this.option;
        }
      }      
      return step;
    });
  }

  fnPickThis(step_id: any=1, opt_id: any=1, next_step_id: any=1, pickThis: boolean=true) {
    this.scrollToTop.setScrollTop();
    this.fnSelectOption(step_id, opt_id, pickThis);
    this.step = {};
    // console.log(next_step_id);
    if(next_step_id > 0){
      this.step = this.myProduct.steps.find(stp=>next_step_id === stp.id);
      this.option = this.step.options[0];
      this.option.selected = true;
      this.step.selected = true;

      
    }   
  }

  fnWhatYouGet(step_id, opt_id){
    this.scrollToTop.setScrollTop();
    this.fnPickThis(step_id, opt_id, 0);    
    this.selectedProduct = this.myProduct;
    this.selectedProduct.name = this.myProduct.steps[0].option.title + ' ' + this.myProduct.steps[1].option.title;
    this.selectedProduct.pictures = this.myProduct.steps[0].option.pictures.map((img) => this.productPath + img);
    this.selectedProduct.bigPictures = this.productPath + this.myProduct.steps[0].option.bigPictures;
    // this.selectedProduct.thumbnail = this.productPath + 'blades-thumbs/' + this.myProduct.steps[0].option.thumbnail;
    this.selectedProduct.thumbnail = this.productPath + this.myProduct.steps[0].option.thumbnail;
    this.selectedProduct.price = this.myProduct.steps[0].option.price;
    this.selectedProduct.salePrice = this.myProduct.steps[0].option.salePrice;
    this.selectedProduct.shippingPrice = this.myProduct.steps[0].option.shippingPrice;
    this.selectedProduct.shippingAddOnPrice = this.myProduct.steps[0].option.addOnPrice;
    this.selectedProduct.shortDetails = this.myProduct.steps[0].options.shortDetails;
    this.selectedProduct.description = this.myProduct.steps[0].option.description;
    this.selectedProduct.stock = this.myProduct.stock;
    this.selectedProduct.new = this.myProduct.new;
    this.selectedProduct.sale = this.myProduct.steps[0].option.sale;
    this.selectedProduct.category = this.myProduct.category;
    this.selectedProduct.battingStyles = [{id: 1, style: 'Left', selected: false}, {id: 2, style: 'Right', selected: true}];
    this.selectedProduct.battingStyle = this.myProduct.steps[0].option.battingStyle != "none"?this.myProduct.steps[0].option.battingStyle:'';
    
    this.whatYouGet = true;
    // this.productsService.customSelectedProduct = JSON.parse(JSON.stringify(this.selectedProduct));
    setTimeout(() => {
      this.productsService.customSelectedProduct = JSON.parse(JSON.stringify(this.selectedProduct));
      this.router.navigate(['/what-you-get/' + step_id]); 
    }, 100);
  }

  fnSetPrice() {
    // const pr = this.productsService.customProductPrice.find((p) => p.id === parseInt(this.common.country.id));
    // const price = JSON.parse(JSON.stringify(pr));
    this.steps[0].options.map((opt) => {
      opt.formatedPrice = '<p>R.R.P.&nbsp;' + opt.price + '</p>';

      // price.bladePrice.map((p) => {
      //   if (p.id === opt.id) {
      //     opt.price = p.price;
      //     opt.salePrice = p.price;
      //     opt.formatedPrice = '<p>R.R.P.&nbsp;' + p.price + '</p>';
      //   }
      // });
    });
  }

  _typeOf (param: any) {
    return typeof(param);
  }

  ngOnInit() {
    let payload = { session_id: this.common.session_id, id: 12, country_id: this.commonService.country_id }
    this.http
    .post<DataTablesResponse>(
      this.common.apiURL + 'products',
      payload, {headers:this.common.headers()}
    ).subscribe(resp => {
      
      resp.data.steps[0].options = resp.data.steps[0].options.filter((opt)=>(opt.activeForCountry === 0 || opt.activeForCountry === this.commonService.country.id));

      this.steps = resp.data.steps;
      this.myProduct.new = resp.data.new;
      this.myProduct.sale = resp.data.sale;
      this.myProduct.category = resp.data.category;
      this.myProduct.currency = resp.data.currency;
      this.fnSetPrice();

      this.scrollToTop.setScrollTop();
      if (this.route.snapshot.params['step_id']) {
        if (!this.productsService.customProduct.id) {
          if(this.steps[0]){
            this.fnReset(this.steps[0].id, this.steps[0].options[0].id);
            console.log("Re-set hitted 1")
          }
          
          this.router.navigate(['/blades/12']);
        }
      } else {
        if(this.steps[0]){
          this.fnReset(this.steps[0].id, this.steps[0].options[0].id);
          console.log("Re-set hitted 2")
        }
      }
    });

    // this.fnSetPrice();
        
  }
}