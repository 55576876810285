import { Component, OnInit, Input, SimpleChange } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';
import { Product } from 'src/app/shared/classes/product';
import { CartItem } from 'src/app/shared/classes/cart-item';
import { ToastrService } from 'ngx-toastr';
import { ProductsService } from 'src/app/shared/services/products.service';
import { CartService } from 'src/app/shared/services/cart.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-what-you-get',
  templateUrl: './what-you-get.component.html',
  styleUrls: ['./what-you-get.component.scss']
})
export class WhatYouGetComponent implements OnInit {

  // @Input() selectedProduct:any = {};

  public selectedProduct:any = {};
  public showMyElement: any;

  public product            :   Product = {};
  public shoppingCartItems  :   CartItem[] = [];
  public battingStyle: string = "right";

  constructor(
    private router: Router,
    public commonService: CommonService,
    private toastrService: ToastrService,
    public productsService: ProductsService,
    private cartService: CartService
  ) {  }

  // private selectedProduct:any = { id: 10, image: "what-you-get_emperor.png"};
  public options:any = { oiling: false, handKnockIn: false, minimalTaping: false, fullTaping: false, fiber_threading: false };

  // ngOnChanges(changes: SimpleChange){       
  //   for (let propName in changes) {  
  //     let change = changes[propName];
  //     console.log(change);
  //     if ( propName == 'selectedProduct' && change && !change.isFirstChange() ) {
  //       this.selectedProduct = change;
  //     }
  //   }
  // }
  
  ngOnInit() {
    console.log("Selected Product: ---> ",this.productsService.customSelectedProduct);
    if (!this.productsService.customSelectedProduct.id) {
      this.router.navigate(['/blades/12']);
    }else{
      this.selectedProduct = this.productsService.customSelectedProduct;
    }   
  }

  fnMyProduct(customProduct, qty): void{
    let product : any = {};
    
    product.id = customProduct.id;
    product.name = customProduct.name?customProduct.name:customProduct.title;
    product.pictures = [customProduct.thumbnail];
    product.price = customProduct.price;
    product.salePrice = customProduct.salePrice;
    product.shippingPrice = customProduct.shippingPrice;
    product.shippingAddOnPrice = customProduct.shippingAddOnPrice;
    product.shortDetails = customProduct.shortDetails;
    product.description = customProduct.description;
    product.stock = customProduct.stock?customProduct.stock:1;
    product.new = customProduct.new;
    product.sale = customProduct.sale;
    product.category = customProduct.category;
    product.currency = customProduct.currency;
    product.otherDetails = customProduct.steps;
    product.options = this.options;
    product.battingStyle = customProduct.battingStyle;

    setTimeout(() => {
      console.log("Final product: --> ", product);
      this.addToCart(product, qty);
    }, 100);
  }

  // If item is aleready added
  public hasItem(product: Product): boolean {
    return false;
    const item = this.shoppingCartItems.find(item => item.product.id === product.id);
    return item !== undefined;
  }

  // Add to cart
  public addToCart(product: Product, quantity) {
    if (quantity == 0) return false;
    if (this.hasItem(product)) {
        var item = this.shoppingCartItems.filter(item => item.product.id === product.id)[0];
        const index = this.shoppingCartItems.indexOf(item);
        let qty = this.shoppingCartItems[index].quantity + parseInt(quantity);
        let stock = this.shoppingCartItems[index].product.stock;
        if(stock < qty) {
           this.toastrService.error('You can not add more items than available. In stock '+ stock +' items.'); // toasr services
        } else {
           this.cartService.addToCart(product, parseInt(quantity));
          //  this.toastrService.success('This product has been added to cart.','', { timeOut: 1000 });
        }
    } else {
      if(product.stock < quantity) {
        this.toastrService.error('You can not add more items than available. In stock '+ product.stock +' items.'); // toasr services
      } else {
        this.cartService.addToCart(product, parseInt(quantity), 'blades');
        // this.toastrService.success('This product has been added to cart.','', { timeOut: 1000 });
      }
    }

    this.router.navigate(['/cart']);
  }

}
