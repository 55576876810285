import { Injectable } from '@angular/core';
import { Product } from '../classes/product';
import { CartItem } from '../classes/cart-item';
import { Order } from '../classes/order';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CommonService, DataTablesResponse } from './common.service';
import { CartService } from './cart.service';


@Injectable({
  providedIn: 'root'
})

export class OrderService {
  
  // Array
  public OrderDetails;

  constructor(
    private router: Router,
    private http: HttpClient,
    public commonService: CommonService,
    private cartService: CartService
  ) { }

  // Get order items
  public getOrderItems() : Order {
    return this.OrderDetails;
  }

  // Create order
  public createOrder(product: any, details: any, paymentId: any, amount: any, shippingPrice: any, discount:any, grandTotal: any) {
    var item = {
        shippingDetails: details,
        products: product,
        currency: this.commonService.currency,
        paymentId: paymentId,
        totalAmount: amount,
        shippingPrice: shippingPrice,
        discount: discount,
        grandTotal: grandTotal,
        createdOn: ""
    };
    this.OrderDetails = item;

    // console.log(this.OrderDetails);

    let payload:any = {};
    payload = this.OrderDetails;
    payload.shippingDetails = JSON.stringify(payload.shippingDetails);
    payload.products = JSON.stringify(payload.products);
    payload.discount = JSON.stringify(payload.discount);
    payload.session_id = this.commonService.session_id;
      this.http
      .post<DataTablesResponse>(
        this.commonService.apiURL + 'addorder',
        payload, {headers:this.commonService.headers()}
      ).subscribe(resp => {
        if(resp.data.id && resp.data.id > 0){
          resp.data.orderId = 10000000 + parseInt(resp.data.id);
          this.OrderDetails = resp.data;
          this.cartService.clearCart();
          this.router.navigate(['/checkout/success']);
        }
      },error=>{
        console.log(error);
      });

    // this.router.navigate(['/checkout/success']);

  }

}
