import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService, DataTablesResponse } from '../services/common.service';
import { HttpClient } from '@angular/common/http';
import { ProductsService } from '../services/products.service';
import { CartService } from '../services/cart.service';
import { CartItem } from '../classes/cart-item';
import { Product } from '../classes/product';
import { Observable, of } from 'rxjs';
declare var $:any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private router: Router,
    public commonService: CommonService,
    public cartService: CartService,
    public productService: ProductsService
  ) {
   }
  windowScrolled: boolean;
  public country: any = this.commonService.country;
  public cartItems      :  Observable<CartItem[]> = of([]);
  public items  :  CartItem[] = [];

  // private cartItems: CartItem[];

  
  onWindowScroll() {
    if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
        this.windowScrolled = true;
    } 
   else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
        this.windowScrolled = false;
        }
    }
    scrollToTop() {
        (function smoothscroll() {
            var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.requestAnimationFrame(smoothscroll);
                window.scrollTo(0, currentScroll - (currentScroll / 8));
            }
        })();
    }

  ngOnInit() {
    // this.fnGetCountry();
    // this.router.routeReuseStrategy.shouldReuseRoute = function () {
    //   return true;
    // };

    if(this.commonService.currencyPopup){
      $('#countryPopup').modal('show');
    }
  }

  showCross() {
    if(localStorage.getItem('cross')){
      return "crossShow"
    } else {
      return "crossNone"
    }
  }
  
  fnChangeLocation(){
    this.commonService.currencyPopup = true;
    setTimeout(() => {
      this.router.navigate(['/home']);
      this.fnOpenCountryPopup();
    }, 50);        
  }

  fnOpenCountryPopup(){
    $('#countryPopup').modal('show');
  }
  
  fnCloseCountryPopup(){
    this.commonService.currencyPopup = false;
    setTimeout(()=>{
      $('#countryPopup').modal('hide');
    },50)
  }

  fnGetItems(country_id:any=""){
    let payload:any = { session_id: this.commonService.session_id, country_id: country_id };
    this.http
      .post<DataTablesResponse>(
        this.commonService.apiURL + 'products',
        payload, {headers: this.commonService.headers()})
        .subscribe(resp => {
          // this.products = resp.data;
          this.cartItems = this.cartService.getItems();
          this.cartItems.subscribe(products => this.items = products);

          // this.items.map((item)=>{
          //   if(item.product.id === 12){
          //     // console.log(this.productService.customProductPrice);
          //     // console.log(this.country);
          //     // console.log(this.productService.customProductPrice.find((country)=>country.id === parseInt(this.country.id)));

          //     let bladePrices = this.productService.customProductPrice.find((country)=>country.id === parseInt(this.country.id)).bladePrice;
          //     let price = bladePrices.find((blade)=>blade.id === item.product.otherDetails[0].options[0].id);
          //     item.product.price = parseInt(price.price.toString());
          //     item.product.salePrice = parseInt(price.salePrice.toString());
          //     item.product.shippingPrice = parseInt(price.shippingPrice.toString());
          //   }else{
          //     item.product = resp.data.find((product)=>product.id === item.product.id);
          //   }            
          // })
        });
  }

  fnMyCountry(id:any, sortOrder:any=0){
    if(id > 0){
      this.commonService.country = {};

      setTimeout(()=>{
        this.commonService.country = JSON.parse(JSON.stringify(this.commonService.countries.filter((country)=>country.id == id && country.sortOrder == sortOrder)[0]));
        // console.log(this.commonService.country);
        this.country = this.commonService.country;
        localStorage.setItem('country', JSON.stringify(this.commonService.country));        
        this.commonService.country_id = JSON.parse(JSON.stringify(this.country.id));
        this.commonService.currency = JSON.parse(JSON.stringify(this.country.currency));
        
        this.fnGetItems(this.country.id);

        this.cartService.clearCart();
      },100)
      setTimeout(()=>{
        this.commonService.currencyPopup = false;
        localStorage.setItem('currencyPopup', this.commonService.currencyPopup.toString());
        localStorage.setItem('cross', 'true')
        $('#countryPopup').modal('hide');
      },50)
    }
  }

  fnOpenAlertPopup(){
    $('#countryAlertPopup').modal('show');
  }

  fnCancelContinue(){
    $('#countryAlertPopup').modal('show');
  }

  openDoc(pdfUrl: string, startPage: number ) {
    window.open(pdfUrl + '#page=' + startPage, '_blank', '');
  }

  // fnMyCountry(id:any){
  //   if(id > 0){
  //     this.commonService.country = {};
  //     setTimeout(()=>{
  //       this.country = JSON.parse(JSON.stringify(this.commonService.countries.filter((country)=>country.id==id)[0]));
  //       this.commonService.country = this.country;
  //       localStorage.setItem('country', JSON.stringify(this.commonService.country));        
        
  //       this.commonService.country_id = JSON.parse(JSON.stringify(this.country.id));
  //       this.commonService.currency = JSON.parse(JSON.stringify(this.country.currency)); 
  //       console.log(this.country);
  //       // this.productsService.getProducts();
  //       // this.router.onSameUrlNavigation = "reload";
        
  //       // if(JSON.stringify(localStorage.getItem('cart'))){
  //       //   localStorage.setItem('tmpCart', localStorage.getItem('cart'));
  //       // }
  //       // const cartItems = JSON.parse(JSON.stringify(JSON.parse(localStorage.getItem('tmpCart'))));

  //       this.router.navigate([this.router.url]);
  //       // this.router.routeReuseStrategy.shouldReuseRoute = function () {
  //       //   return false;
  //       // };
  //       // if(cartItems){
  //       //   for(var i=0;i<cartItems.length;i++){
  //       //     if(cartItems[i].product){
  //       //       let quantity = cartItems[i].quantity;
  //       //       this.cartService.removeFromCart(cartItems[i]);
  //       //       let payload = { session_id: this.commonService.session_id, id: id, country_id: this.commonService.country_id, product_id:cartItems[i].product.id }
  //       //       this.http
  //       //       .post<DataTablesResponse>(
  //       //         this.commonService.apiURL + 'products',
  //       //         payload, {headers:this.commonService.headers()}
  //       //       ).subscribe(resp => {
  //       //         let product: Product = resp.data;
  //       //         this.cartService.addToCart(product, quantity);
  //       //       });
  //       //     }
  //       //   }
  //       // }
        
  //     },30)
  //     setTimeout(()=>{
  //       this.commonService.currencyPopup = false;
  //       localStorage.setItem('currencyPopup', this.commonService.currencyPopup.toString());
  //     },50)
  //   }
  // }
}
