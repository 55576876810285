import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Product } from '../classes/product';
import { BehaviorSubject, Observable, of} from 'rxjs';
import { map, filter, scan } from 'rxjs/operators';
import 'rxjs/add/operator/map';

import { CommonService, DataTablesResponse } from '../../shared/services/common.service';

@Injectable({
  providedIn: 'root'
})

export class ProductsService {
  
  public compareProducts: BehaviorSubject<Product[]> = new BehaviorSubject([]);
  public compareProduct: Product[] = [];
  public allProducts: Product[] = [];
  public customProduct:any = {};
  public customSelectedProduct:any = {};
  // public customProductPrice = [
  //   {id:1,coutry:"America (USA)",currency:"USD",bladePrice:[
  //     { country_id: 1, step_id: 1, option_id:1, price: 185, salePrice: 185, shippingPrice: 32 },
  //     { country_id: 1, step_id: 1, option_id:2, price: 225, salePrice: 225, shippingPrice: 32 },
  //     { country_id: 1, step_id: 1, option_id:3, price: 275, salePrice: 275, shippingPrice: 32 },
  //     { country_id: 1, step_id: 1, option_id:4, price: 349, salePrice: 349, shippingPrice: 32 },
  //     { country_id: 1, step_id: 1, option_id:5, price: 449, salePrice: 449, shippingPrice: 32 },
  //     { country_id: 1, step_id: 1, option_id:6, price: 539, salePrice: 539, shippingPrice: 32},
  //     { country_id: 1, step_id: 1, option_id:7, price: 625, salePrice: 625, shippingPrice: 32 }
  //   ]},
  //   {id:2,coutry:"England (UK)",currency:"GBP",bladePrice:[
  //     { country_id: 2, step_id: 1, option_id:1, price: 149, salePrice: 149, shippingPrice: 25 },
  //     { country_id: 2, step_id: 1, option_id:2, price: 179, salePrice: 179, shippingPrice: 25 },
  //     { country_id: 2, step_id: 1, option_id:3, price: 219, salePrice: 219, shippingPrice: 25 },
  //     { country_id: 2, step_id: 1, option_id:4, price: 279, salePrice: 279, shippingPrice: 25 },
  //     { country_id: 2, step_id: 1, option_id:5, price: 349, salePrice: 349, shippingPrice: 25 },
  //     { country_id: 2, step_id: 1, option_id:6, price: 425, salePrice: 425, shippingPrice: 25 },
  //     { country_id: 2, step_id: 1, option_id:7, price: 499, salePrice: 499, shippingPrice: 25 }
  //   ]},
  //   {id:3,coutry:"India",currency:"INR",bladePrice:[
  //     { country_id: 3, step_id: 1, option_id:1, price: 10999, salePrice: 10999, shippingPrice: 0 },
  //     { country_id: 3, step_id: 1, option_id:2, price: 14499, salePrice: 14499, shippingPrice: 0 },
  //     { country_id: 3, step_id: 1, option_id:3, price: 17499, salePrice: 17499, shippingPrice: 0 },
  //     { country_id: 3, step_id: 1, option_id:4, price: 22999, salePrice: 22999, shippingPrice: 0 },
  //     { country_id: 3, step_id: 1, option_id:5, price: 28499, salePrice: 28499, shippingPrice: 0 },
  //     { country_id: 3, step_id: 1, option_id:6, price: 34499, salePrice: 34499, shippingPrice: 0 },
  //     { country_id: 3, step_id: 1, option_id:7, price: 37999, salePrice: 37999, shippingPrice: 0 }
  //   ]},
  //   {id:4,coutry:"Europe",currency:"EUR",bladePrice:[
  //     { country_id: 4, step_id: 1, option_id:1, price: 165, salePrice: 165, shippingPrice: 30 },
  //     { country_id: 4, step_id: 1, option_id:2, price: 199, salePrice: 199, shippingPrice: 30 },
  //     { country_id: 4, step_id: 1, option_id:3, price: 249, salePrice: 249, shippingPrice: 30 },
  //     { country_id: 4, step_id: 1, option_id:4, price: 325, salePrice: 325, shippingPrice: 30 },
  //     { country_id: 4, step_id: 1, option_id:5, price: 399, salePrice: 399, shippingPrice: 30 },
  //     { country_id: 4, step_id: 1, option_id:6, price: 489, salePrice: 489, shippingPrice: 30 },
  //     { country_id: 4, step_id: 1, option_id:7, price: 575, salePrice: 575, shippingPrice: 30 },
  //   ]},
  //   {id:5,coutry:"Australia",currency:"AUD",bladePrice:[
  //     { country_id: 5, step_id: 1, option_id:1, price: 249, salePrice: 249, shippingPrice: 40 },
  //     { country_id: 5, step_id: 1, option_id:2, price: 299, salePrice: 299, shippingPrice: 40 },
  //     { country_id: 5, step_id: 1, option_id:3, price: 369, salePrice: 369, shippingPrice: 40 },
  //     { country_id: 5, step_id: 1, option_id:4, price: 475, salePrice: 475, shippingPrice: 40 },
  //     { country_id: 5, step_id: 1, option_id:5, price: 599, salePrice: 599, shippingPrice: 40 },
  //     { country_id: 5, step_id: 1, option_id:6, price: 729, salePrice: 729, shippingPrice: 40 },
  //     { country_id: 5, step_id: 1, option_id:7, price: 849, salePrice: 849, shippingPrice: 40 },
  //   ]},
  //   {id:6,coutry:"Canada",currency:"CAD",bladePrice:[
  //     { country_id: 6, step_id: 1, option_id:1, price: 245, salePrice: 245, shippingPrice: 40 },
  //     { country_id: 6, step_id: 1, option_id:2, price: 295, salePrice: 295, shippingPrice: 40 },
  //     { country_id: 6, step_id: 1, option_id:3, price: 365, salePrice: 365, shippingPrice: 40 },
  //     { country_id: 6, step_id: 1, option_id:4, price: 465, salePrice: 465, shippingPrice: 40 },
  //     { country_id: 6, step_id: 1, option_id:5, price: 575, salePrice: 575, shippingPrice: 40 },
  //     { country_id: 6, step_id: 1, option_id:6, price: 699, salePrice: 699, shippingPrice: 40 },
  //     { country_id: 6, step_id: 1, option_id:7, price: 835, salePrice: 835, shippingPrice: 40 },
  //   ]}
  // ];

  // Initialize 
  constructor(private http: Http,private toastrService: ToastrService, private commonService: CommonService) { 
     this.compareProducts.subscribe(products => this.compareProduct = products);
  }

  // Observable Product Array
  private products(): Observable<Product[]> {
    // product = this.http.get('assets/data/fashion/products.json').map((res:any) => res.json())
    // setTimeout(()=>{
      return this.http.get(this.commonService.apiURL + 'products/' + this.commonService.session_id + '/' + this.commonService.country_id).map((res:any) => {
        if(res['data']){return res['data'].json();}
        else{return this.allProducts;}
      });
    // },300)
    //  return this.allProducts;
  }

  // Get Products
  public getProducts(): Observable<Product[]> {
    return this.products();
  }

  // Get Products By Id
  public getProduct(id: number): Observable<Product> {
    return this.products().pipe(map(items => { return items.find((item: Product) => { return item.id === id; }); }));
  }

   // Get Products By category
  public getProductByCategory(category: any): Observable<Product[]> {
    return this.products().pipe(map(items => 
       items.filter((item: Product) => {
         if(category == 'all')
            return item
         else
            return item.category == category; 
        
       })
     ));
  }

  // Get Products
  public getComapreProducts(): Observable<Product[]> {
    return this.compareProducts.asObservable();
  }

  // If item is aleready added In compare
  public hasProduct(product: Product): boolean {
    const item = this.compareProduct.find(item => item.id === product.id);
    return item !== undefined;
  }

  // Add to compare
  public addToCompare(product: Product): Product | boolean {
    var item: Product | boolean = false;
    if (this.hasProduct(product)) {
      item = this.compareProduct.filter(item => item.id === product.id)[0];
      const index = this.compareProduct.indexOf(item);
    } else {
      if(this.compareProduct.length < 4)
        this.compareProduct.push(product);
      else 
        this.toastrService.warning('Maximum 4 products are in compare.'); // toasr services
    }
      return item;
  }

  // Removed Product
  public removeFromCompare(product: Product) {
    if (product === undefined) { return; }
    const index = this.compareProduct.indexOf(product);
    this.compareProduct.splice(index, 1);
  }
   
}