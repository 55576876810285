import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BarRatingModule } from "ngx-bar-rating";
import { RangeSliderModule  } from 'ngx-rangeslider-component';
// import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxPayPalModule } from 'ngx-paypal';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

/// Import the library
import { NgxImgZoomModule } from 'ngx-img-zoom';
import { ScrollTopService } from '../shared/services/scrolltotop.service';

// Home components
import { HomeComponent } from './home/home.component';
import { SliderComponent } from './home/slider/slider.component';
import { ServicesComponent } from './home/services/services.component';
import { LogoComponent } from './home/logo/logo.component';
// Products Components 
import { CustomProductComponent } from './custom-product/custom-product.component';
import { CategoryComponent } from './category/category.component';
import { SingleCategoryComponent } from './category/widgets/single-category/single-category.component';
import { ProductsComponent } from './products/products.component';
import { SingleProductComponent } from './products/single-product/single-product.component';
import { WhatYouGetComponent } from './custom-product/what-you-get/what-you-get.component';
import { Routes, RouterModule } from '@angular/router';
import { ProductComponent } from './product/product.component';
import { ArmourProductComponent } from './product/product-details/armour-product/armour-product.component';
import { AboutUsComponent } from '../pages/about-us/about-us.component';
import { PagesModule } from '../pages/pages.module';
import { CartComponent } from './product/cart/cart.component';
import { CheckoutComponent } from './product/checkout/checkout.component';
import { SuccessComponent } from './product/success/success.component';
import { FaqComponent } from '../pages/faq/faq.component';
import { ProductCareComponent } from '../pages/product-care/product-care.component';
import { DeliveryComponent } from '../pages/delivery/delivery.component';
import { WarrantyComponent } from '../pages/warranty/warranty.component';
import { TermsConditionsComponent } from '../pages/terms-conditions/terms-conditions.component';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { ReturnsComponent } from '../pages/returns/returns.component';
import { from } from 'rxjs';
import { ZoomPopupComponent } from './product/product-details/armour-product/zoom-popup/zoom-popup.component';
import { FeedbackFormComponent } from '../feedback-form/feedback-form.component';
import { ErrorPageComponent } from '../pages/error-page/error-page.component';
import { ReviewsComponent } from './reviews/reviews.component';

const routes: Routes = [
  // { 
  //   path: '', 
  //   redirectTo: 'home', 
  //   pathMatch: 'full' 
  // },
  { 
    path: '', 
    component: HomeComponent
  },
  { 
    path: 'home', 
    component: HomeComponent
  },
  {
    path: 'categories',
    component: CategoryComponent
  },
  {
    path: 'categories/:category',
    component: CategoryComponent
  },
  {
    path: 'products/:category',
    component: ProductsComponent
  },
  {
    path: 'blades/:id',
    component: CustomProductComponent
  },
  {
    path: 'blade-step/:step_id',
    component: CustomProductComponent
  },
  {
    path: 'what-you-get/:step_id',
    component: WhatYouGetComponent
  },
  {
    path: 'product/:id',
    component: ArmourProductComponent
  },
  {
    path: 'about-us',
    component: AboutUsComponent
  },
  {
    path: 'bat-care',
    component: ProductCareComponent
  },
  {
    path: 'shipping',
    component: DeliveryComponent
  },
  {
    path: 'policy',
    component: ReturnsComponent
  },
  {
    path: 'warranty',
    component: WarrantyComponent
  },
  {
    path: 'terms-conditions',
    component: TermsConditionsComponent
  },
  {
    path: 'faqs',
    component: FaqComponent
  },
  {
    path: 'contact-us',
    component: ContactUsComponent
  },
  {
    path: 'contact-us/',
    component: ContactUsComponent
  },
  {
    path: 'expert-advice/',
    component: FeedbackFormComponent
  },
  {
    path: 'cart',
    component: CartComponent
  },
  {
    path: 'checkout',
    component: CheckoutComponent
  },
  {
    path: 'checkout/success',
    component: SuccessComponent
  },
  {
    path: 'error',    
    component: ErrorPageComponent
  },
  {
    path: 'reviews',
    component: ReviewsComponent
  },
  { 
    path: '**', 
    redirectTo: 'error'
  }
];


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    SlickCarouselModule,
    BarRatingModule,
    RangeSliderModule,
    // InfiniteScrollModule,
    NgxPayPalModule,
    PagesModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    NgxImgZoomModule,
    RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'}),
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})

    // RouterModule.forRoot(routes, { useHash: false, anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled' })
  ],
  declarations: [
    HomeComponent,
    SliderComponent,
    ServicesComponent,
    LogoComponent,
    CustomProductComponent,
    ProductsComponent,
    WhatYouGetComponent,
    ProductComponent,
    CategoryComponent,
    ArmourProductComponent,
    SingleCategoryComponent,
    SingleProductComponent,
    CartComponent,
    CheckoutComponent,
    SuccessComponent,
    ContactUsComponent,
    ZoomPopupComponent,
    FeedbackFormComponent,
    ReviewsComponent,
  ],
  providers: [CurrencyPipe,ScrollTopService]
})
export class FashionModule { }
