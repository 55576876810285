import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService, DataTablesResponse } from '../shared/services/common.service';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  constructor(
    private http: HttpClient,
    public common: CommonService,
    private formBuilder: FormBuilder
  ) { }

  public contact: any = {fullName:"", email:"", phone:"", message:""};
  contactForm: FormGroup;
  public submitted:boolean = false;
  public error: any = {};
  public showMessage: boolean = false;
  public loading: boolean = false;

  // convenience getter for easy access to form fields
  get f() { return this.contactForm.controls; }

  fnSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.contactForm.invalid) {
      this.scrollToError('.page-heading');
        return;
    }

    let payload:any = this.contactForm.value;
    payload.session_id = 'guest';
    this.http
    .post<DataTablesResponse>(
      this.common.apiURL + 'contactus',
      payload, {headers:this.common.headers()}
    ).subscribe(resp => {
      // this.ngOnInit();
      this.contactForm.reset();
      this.submitted = false;
      this.showMessage = true;
      this.contact = {fullName:"", email:"", phone:"", message:""};
      this.contact.phone = this.common.country.code + '|';
      this.scrollToError('.page-heading');
      setTimeout(()=>{
        this.loading = false;
      }, 300);
      // setTimeout(()=>{
      //   this.showMessage = false;
      // }, 5000);
    },error=>{
      this.scrollToError('.page-heading');
      setTimeout(()=>{
        this.loading = false;
      }, 300);
      // setTimeout(()=>{
        console.log(error);
        // this.showMessage = false;
      // }, 5000);
    });
  }

  ngOnInit() {
    this.scrollToError('.page-heading');
    // this.contact.phone = this.common.country.code;

    this.contactForm = this.formBuilder.group({
      fullName: [this.contact.fullName, Validators.required],
      email: [this.contact.email, [Validators.required, Validators.email]],
      phone: [this.contact.phone, [Validators.pattern('[0-9+]+'), Validators.minLength(7), Validators.maxLength(15)]],
      message: [this.contact.message, Validators.required]
    });
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
  scrollToError(elm: string): void {
    const firstElementWithError = document.querySelector(elm);
    this.scrollTo(firstElementWithError);
  }

}
