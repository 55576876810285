import { Component, OnInit, HostListener, Pipe, PipeTransform, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgModule } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Product } from '../../../../shared/classes/product';
import { CartItem } from '../../../../shared/classes/cart-item';
import { ProductsService } from '../../../../shared/services/products.service';
import { CartService } from '../../../../shared/services/cart.service';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CommonService, DataTablesResponse } from 'src/app/shared/services/common.service';
import { ScrollTopService } from 'src/app/shared/services/scrolltotop.service';
import { ZoomPopupComponent } from './zoom-popup/zoom-popup.component';
declare var $:any;

// @Pipe({
//   name: 'filter'
// })
@Component({
  selector: 'app-armour-product',
  templateUrl: './armour-product.component.html',
  styleUrls: ['./armour-product.component.scss'],
})
export class ArmourProductComponent implements OnInit {

  @ViewChild(ZoomPopupComponent) zoomPopup:ZoomPopupComponent;

  // transform(items: any[], field : string, value : string): any[] {  
  //   if (!items) return [];
  //   if (!value || value.length == 0) return items;
  //   return items.filter(it => 
  //   it[field].toLowerCase().indexOf(value.toLowerCase()) !=-1);
  // }

  public product            :   Product = {};
  public products           :   Product[] = [];
  public cartItems          :   Observable<CartItem[]> = of([]);
  public shoppingCartItems  :   CartItem[] = [];
  public counter            :   any = 1; 
  public addToCartButtonDisable:boolean = false;
  public selectedSize       :   any = 'Men';
  public selectedColor       :   any = '';
  public selectedBattingStyle       :   any = 'Right';
  public screenWidth       
  public slideRightNavConfig

  public productImages:any = [];
  

  private imgStyle = `width:515px; 
                      height:515px;`;
                      // bottom:0;  
                      // left:0;  
                      // right:0;  
                      // top:0;  
                      // margin:0 auto; 
                      // border:1px solid #dcdcdc;`;

  private resultStyle = ` width:600px; 
                          height:550px; 
                          background-repeat: no-repeat; 
                          z-index: 2; 
                          position:absolute;
                          -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
                          box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); 
                          top: 0;left: 100%;`;
  private lensStyle = "width:100px; height:100px";
  
  //Get Product By Id
  constructor(
    private route: ActivatedRoute, 
    private router: Router, 
    private toastrService: ToastrService,
    public productsService: ProductsService, 
    public cartService: CartService,
    private http: HttpClient,
    public common: CommonService,
    public scrollToTop:ScrollTopService
  ) {
      // this.route.params.subscribe(params => {
      //   const id = +params['id'];
      //   this.productsService.getProduct(id).subscribe(product => this.product = product)
      // });
      this.onResize();
  }

  fnGetProduct(id) {
    let payload = { session_id: this.common.session_id, id: id, country_id: this.common.country_id }
    this.http
    .post<DataTablesResponse>(
      this.common.apiURL + 'products',
      payload, {headers:this.common.headers()}
    ).subscribe(resp => {
      this.product = resp.data;
    });
  }

  imageZoom(imgID, resultID) {
    var img, lens, result, cx, cy;
    img = document.getElementById(imgID);
    //img = $(imgID);
    result = document.getElementById(resultID);
    //result = $(resultID);
    /* Create lens: */
    lens = document.createElement("DIV");
    lens.setAttribute("class", "img-zoom-lens");
    /* Insert lens: */
    img.parentElement.insertBefore(lens, img);
    /* Calculate the ratio between result DIV and lens: */
    cx = result.offsetWidth / lens.offsetWidth;
    cy = result.offsetHeight / lens.offsetHeight;
    /* Set background properties for the result DIV */
    result.style.backgroundImage = "url('" + img.src + "')";
    result.style.backgroundSize = (img.width * cx) + "px " + (img.height * cy) + "px";
    /* Execute a function when someone moves the cursor over the image, or the lens: */
    lens.addEventListener("mousemove", moveLens);
    img.addEventListener("mousemove", moveLens);
    /* And also for touch screens: */
    lens.addEventListener("touchmove", moveLens);
    img.addEventListener("touchmove", moveLens);
    function moveLens(e) {
      var pos, x, y;
      /* Prevent any other actions that may occur when moving over the image */
      e.preventDefault();
      /* Get the cursor's x and y positions: */
      pos = getCursorPos(e);
      /* Calculate the position of the lens: */
      x = pos.x - (lens.offsetWidth / 2);
      y = pos.y - (lens.offsetHeight / 2);
      /* Prevent the lens from being positioned outside the image: */
      if (x > img.width - lens.offsetWidth) {x = img.width - lens.offsetWidth;}
      if (x < 0) {x = 0;}
      if (y > img.height - lens.offsetHeight) {y = img.height - lens.offsetHeight;}
      if (y < 0) {y = 0;}
      /* Set the position of the lens: */
      lens.style.left = x + "px";
      lens.style.top = y + "px";
      /* Display what the lens "sees": */
      result.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
    }
    function getCursorPos(e) {
      var a, x = 0, y = 0;
      e = e || window.event;
      /* Get the x and y positions of the image: */
      a = img.getBoundingClientRect();
      /* Calculate the cursor's x and y coordinates, relative to the image: */
      x = e.pageX - a.left;
      y = e.pageY - a.top;
      /* Consider any page scrolling: */
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return {x : x, y : y};
    }
  }

  ngOnInit() {
    this.scrollToTop.setScrollTop();
    // this.productsService.getProducts().subscribe(product => this.products = product);
    this.fnGetProduct(this.route.snapshot.params['id']);
    this.cartItems = this.cartService.getItems();
    this.cartItems.subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems);
    // setTimeout(()=>{
    //   this.imageZoom("myimage", "myresult");
    // },1000)
    
  }
  
  public slideRightConfig = {
    focusOnSelect: true,
    verticalSwiping: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    infinite: true,
    asNavFor: '.slider-right-nav'
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth > 576) {
       return this.slideRightNavConfig = { 
        vertical: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        horizontalSwipping: false,
        arrows: false,
        infinite: false,
        dots: false,
        centerMode: false,
        focusOnSelect: true,
          asNavFor: '.product-right-slick'
      }
   } else {
      return this.slideRightNavConfig = { 
          vertical: false,
          slidesToShow: 4,
          slidesToScroll: 1,
          asNavFor: '.product-right-slick',
          arrows: false,
          infinite: false,
          centerMode: false,
          dots: false,
          focusOnSelect: true,
          responsive: [
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                }
          ]
      }
   }
  }


  fnFilter(arr, key, value){
    return arr.filter((ar)=>ar[key] === value);
  }
  
  fnQtyChange(){
    let stock = JSON.parse(JSON.stringify(this.product.stock));
    stock = parseInt(stock);
    let cart = this.shoppingCartItems.map((item)=>{
      if(item.product.id == this.product.id){
        stock = stock - item.quantity;
      }
    })
    
    if(parseInt(this.counter) > stock){
      this.addToCartButtonDisable = true;
    }else{
      this.addToCartButtonDisable = false;
    }    
  }

  public increment() { 
      this.counter = parseInt(this.counter) + 1;
      this.fnQtyChange();
  }

  public decrement() {
      if(parseInt(this.counter) > 1){
          this.counter = parseInt(this.counter) - 1;
      }
      this.fnQtyChange();
  }

  // If item is aleready added
  public hasItem(product: Product): boolean {
    const item = this.shoppingCartItems.find(item => item.product.id === product.id);
    return item !== undefined;
  }

  // Add to cart
  public addToCart(product: Product, quantity) {
    if (quantity == 0) return false;
    if (this.hasItem(product)) {
        var item = this.shoppingCartItems.filter(item => item.product.id === product.id)[0];
        const index = this.shoppingCartItems.indexOf(item);
        let qty = this.shoppingCartItems[index].quantity + parseInt(quantity);
        let stock = this.shoppingCartItems[index].product.stock;
        stock = parseInt(stock.toString());
        if(stock < qty) {
           this.toastrService.error('You can not add more items than available. In stock '+ stock +' items.'); // toasr services
        } else {
           this.cartService.addToCart(product, parseInt(quantity));
          //  this.toastrService.success('This product has been added to cart.','', { timeOut: 1000 });
        }
    } else {
      product.stock = parseInt(product.stock.toString());
      if(product.stock < quantity) {
        this.toastrService.error('You can not add more items than available. In stock '+ product.stock +' items.'); // toasr services
      } else {
        this.cartService.addToCart(product, parseInt(quantity));
        // this.toastrService.success('This product has been added to cart.','', { timeOut: 1000 });
      }
    }
  }

  // Add to cart
  public buyNow(product: Product, quantity) {
     if (quantity > 0) 
       this.cartService.addToCart(product,parseInt(quantity));
       this.router.navigate(['/home/checkout']);
  }

  // Change size variant
  public changeSizeVariant(variant) {
    this.product.sizes.map((size)=>{if(size.id === variant.id){size.selected = true;}else{size.selected = false;}});
  }

  // Change size variant
  public changeColorVariant(variant) {
    this.product.colors.map((color)=>{if(color.id === variant.id){color.selected = true;}else{color.selected = false;}});
 }

 // Change size variant
 public changeBattingStyleVariant(variant) {
  // this.product.battingStyles.map((style)=>{if(style.id === variant.id){style.selected = true;}else{style.selected = false;}});
  this.product.battingStyle = variant;
}
  
}
