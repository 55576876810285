import { Component, OnInit } from '@angular/core';
import { Order } from '../../../shared/classes/order';
import { OrderService } from '../../../shared/services/order.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-order-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  
  public orderDetails : Order = {};
  public expectedDate : any;
  public isBlade: boolean = false;

  constructor(
    public orderService: OrderService,
    public commonService: CommonService
  ) { }

  ngAfterViewInit() {
    window.scrollTo(0, 0);    
  }

  ngOnInit() {
    this.orderDetails = this.orderService.getOrderItems();
    // this.orderDetails = {
    //   "id":"28",
    //   "subscriber_id":"1",
    //   "shippingDetails":{
    //   "firstName":"Naveen",
    //   "lastName":"Kumar",
    //   "mobile":"+919971910665",
    //   "email":"naveenku@gmail.com",
    //   "address":"428/A",
    //   "country":"India",
    //   "town":"Gurgaon",
    //   "state":"Haryana",
    //   "postalCode":"122001"
    //   },
    //   "paymentId":"pay_Dqlaj3q1Bks4OY",
    //   "products":[
    //   {
    //   "product":{
    //   "id":"23",
    //   "category_id":"5",
    //   "pictures":[
    //   "https://www.armourandblade.com/apis_and_resbucket/resbucket/product/1547669125000_GlovesType1Emperor1.png",
    //   "https://www.armourandblade.com/apis_and_resbucket/resbucket/product/1547669125000_GlovesType1Emperor2.png",
    //   "https://www.armourandblade.com/apis_and_resbucket/resbucket/product/1547669125000_GlovesType1Emperor3.png",
    //   "https://www.armourandblade.com/apis_and_resbucket/resbucket/product/1547669125000_GlovesType1Emperor4.png"
    //   ],
    //   "name":"EMPEROR I",
    //   "shortDetails":"<p>The Type 1 is a classic fit glove with pre-curved fingers for an aided natural grip and ease of control.</p><p><br></p><p>• Palm : Premium pittard leather, added palm layering, webbing cross flow vents</p><p>• Shields : 2 lead fingers on both hands, bottom hand thumb</p><p>• Fingers : Enforced fiber protection on all fingers</p><p>• Thumb : 1 piece cylindrical fiber shield and a shock absorption rubber tip on the bottom hand, mesh and leather hybrid for longer lasting freshness on the top hand</p><p>• Sides : 1 piece HDF protection, stretchable cross flow mesh</p><p>• Wrist : Ultra stretch double sided towel with velcro fastening </p><p>• Back exterior : Select Korean PU </p><p>• Padding : Cross linked cotton and HDF </p><p>• Recommended for : Professionals</p>",
    //   "description":"",
    //   "price":"2",
    //   "salePrice":"0",
    //   "new":"0",
    //   "sale":"0",
    //   "colors":[
    //   {
    //   "id":1,
    //   "color":"White",
    //   "selected":true
    //   }
    //   ],
    //   "sizes":[
    //   {
    //   "id":1,
    //   "size":"Men",
    //   "selected":true
    //   }
    //   ],
    //   "battingStyles":[
    //   {
    //   "id":1,
    //   "style":"Left",
    //   "selected":false
    //   },
    //   {
    //   "id":2,
    //   "style":"Right",
    //   "selected":true
    //   }
    //   ],
    //   "parent":null,
    //   "steps":"0",
    //   "tags":"",
    //   "sortOrder":"1",
    //   "isActive":"1",
    //   "isRemoved":"0",
    //   "createdBy":"1",
    //   "createdOn":"2019-11-16 10:04:46",
    //   "updatedBy":"1",
    //   "updatedOn":"2019-11-16 22:34:46",
    //   "category":"Gloves",
    //   "stock":"12",
    //   "shippingPrice":"0",
    //   "shippingAddOnPrice":"0",
    //   "currency":"INR",
    //   "creator":"Naveen",
    //   "createdDate":"16/11/2019",
    //   "last_category_id":"5"
    //   },
    //   "quantity":1
    //   },
    //   {
    //   "product":{
    //   "id":"23",
    //   "category_id":"5",
    //   "pictures":[
    //   "https://www.armourandblade.com/apis_and_resbucket/resbucket/product/1547669125000_GlovesType1Emperor1.png",
    //   "https://www.armourandblade.com/apis_and_resbucket/resbucket/product/1547669125000_GlovesType1Emperor2.png",
    //   "https://www.armourandblade.com/apis_and_resbucket/resbucket/product/1547669125000_GlovesType1Emperor3.png",
    //   "https://www.armourandblade.com/apis_and_resbucket/resbucket/product/1547669125000_GlovesType1Emperor4.png"
    //   ],
    //   "name":"EMPEROR I",
    //   "shortDetails":"<p>The Type 1 is a classic fit glove with pre-curved fingers for an aided natural grip and ease of control.</p><p><br></p><p>• Palm : Premium pittard leather, added palm layering, webbing cross flow vents</p><p>• Shields : 2 lead fingers on both hands, bottom hand thumb</p><p>• Fingers : Enforced fiber protection on all fingers</p><p>• Thumb : 1 piece cylindrical fiber shield and a shock absorption rubber tip on the bottom hand, mesh and leather hybrid for longer lasting freshness on the top hand</p><p>• Sides : 1 piece HDF protection, stretchable cross flow mesh</p><p>• Wrist : Ultra stretch double sided towel with velcro fastening </p><p>• Back exterior : Select Korean PU </p><p>• Padding : Cross linked cotton and HDF </p><p>• Recommended for : Professionals</p>",
    //   "description":"",
    //   "price":"2",
    //   "salePrice":"0",
    //   "new":"0",
    //   "sale":"0",
    //   "colors":[
    //   {
    //   "id":1,
    //   "color":"White",
    //   "selected":true
    //   }
    //   ],
    //   "sizes":[
    //   {
    //   "id":1,
    //   "size":"Men",
    //   "selected":true
    //   }
    //   ],
    //   "battingStyles":[
    //   {
    //   "id":1,
    //   "style":"Left",
    //   "selected":false
    //   },
    //   {
    //   "id":2,
    //   "style":"Right",
    //   "selected":true
    //   }
    //   ],
    //   "parent":null,
    //   "steps":"0",
    //   "tags":"",
    //   "sortOrder":"1",
    //   "isActive":"1",
    //   "isRemoved":"0",
    //   "createdBy":"1",
    //   "createdOn":"2019-11-16 10:04:46",
    //   "updatedBy":"1",
    //   "updatedOn":"2019-11-16 22:34:46",
    //   "category":"Gloves",
    //   "stock":"12",
    //   "shippingPrice":"0",
    //   "shippingAddOnPrice":"0",
    //   "currency":"INR",
    //   "creator":"Naveen",
    //   "createdDate":"16/11/2019",
    //   "last_category_id":"5"
    //   },
    //   "quantity":1
    //   }
    //   ],
    //   "currency":"INR",
    //   "totalAmount":"2",
    //   "shippingPrice":"0",
    //   "discount":{
    //   "id":0,
    //   "discountPer":0,
    //   "promoCode":"",
    //   "amount":0
    //   },
    //   "grandTotal":"2",
    //   "remarks":"",
    //   "sortOrder":"0",
    //   "status":"Pending",
    //   "isActive":"1",
    //   "isRemoved":"0",
    //   "createdBy":"0",
    //   "createdByUserId":"0",
    //   "createdOn":"2019-12-11 09:46:57",
    //   "updatedBy":"0",
    //   "updatedByUserId":"0",
    //   "updatedOn":"2019-12-10 21:16:57",
    //   "mail":"Your mail has been sent successfully.",
    //   "orderId":10000028
    //   };
    
    // console.log(this.orderDetails)
  }

  fnExpectedDate(d){
    let date = new Date(d);
    date.setDate(date.getDate() + 10);
    return date;
  }
}
