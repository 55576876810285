import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Product } from '../../../shared/classes/product';
import { CartItem } from '../../../shared/classes/cart-item';
import { CartService } from '../../../shared/services/cart.service';
import { Observable, of } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { ScrollTopService } from 'src/app/shared/services/scrolltotop.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  public cartItems          :   Observable<CartItem[]> = of([]);
  public shoppingCartItems  :   CartItem[] = [];
 
  constructor(
    public toastrService: ToastrService, 
    public cartService: CartService,
    public commonService: CommonService,
    public scrollToTop:ScrollTopService
  ) {}

  ngOnInit() {
    this.scrollToTop.setScrollTop();
  	this.cartItems = this.cartService.getItems();
    this.cartItems.subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems);

    this.fnShippingPrice();
  }

  fnShippingPrice(){
    let shippingPrice = 0;
    let maxPrice = 0;
    console.log("fnShippingPrice()", this.shoppingCartItems);

    this.shoppingCartItems = this.shoppingCartItems.map((item, i)=>{

      // item.product['spPrice'] = 0;
      // item.product['addonspPrice'] = item.product.shippingAddOnPrice;
      console.log("Price diff -------> ", item.product.shippingPrice, ' > ', shippingPrice, ' == ', item.product.shippingPrice, ' -- ', item.product.shippingAddOnPrice);
      // console.log()
      // if(item.product.shippingPrice > shippingPrice){
        // shippingPrice = item.product.shippingPrice;
        // item.product['spPrice'] = shippingPrice;
      if(parseFloat(item.product.shippingPrice.toString()) > parseFloat(maxPrice.toString())){        
        maxPrice = item.product.shippingPrice;
        shippingPrice = item.product.shippingPrice;
        item.product['spPrice'] = shippingPrice;
        item.product['addonspPrice'] = 0;

        console.log("price - ", item.product.price);
        console.log("maxPrice - ", maxPrice);
        console.log("spPrice - ", item.product['spPrice']);        
        console.log("addonspPrice - ", item.product['addonspPrice']);

        this.shoppingCartItems.forEach((itm, j)=>{
          if(itm.product.id != item.product.id){
            this.shoppingCartItems[j].product['spPrice'] = 0;
            this.shoppingCartItems[j].product['addonspPrice'] = this.shoppingCartItems[j].product.shippingAddOnPrice;
          }
        })
      }

      return item;
    })
  }

  // If item is aleready added
  public hasItem(product: Product): boolean {
    const item = this.shoppingCartItems.find(item => item.product.id === product.id);
    return item !== undefined;
  }
 
  // Increase Product Quantity
  public increment(product: any, quantity: number = 1) {
    if (this.hasItem(product)) {
        var item = this.shoppingCartItems.filter(item => item.product.id === product.id)[0];
        const index = this.shoppingCartItems.indexOf(item);
        let qty = this.shoppingCartItems[index].quantity + quantity;
        let stock = this.shoppingCartItems[index].product.stock;
        if (qty == 0) return false;
        if(stock < qty) {
           this.toastrService.error('You can not add more items than available. In stock '+ stock +' items.'); // toasr services
        } else {
           this.cartService.addToCart(product, quantity);
        }
    }
  }
  
  // Decrease Product Quantity
  public decrement(product: any, quantity: number = -1) {
    if (this.hasItem(product)) {
        var item = this.shoppingCartItems.filter(item => item.product.id === product.id)[0];
        const index = this.shoppingCartItems.indexOf(item);
        let qty = this.shoppingCartItems[index].quantity + quantity;
        let stock = this.shoppingCartItems[index].product.stock;
        if (qty == 0) return false;
        if(stock < qty) {
           this.toastrService.error('You can not add more items than available. In stock '+ stock +' items.'); // toasr services
        } else {
           this.cartService.addToCart(product, quantity);
        }
    }
  }
  
  // Get Total
  public getTotal(): Observable<number> {
    return this.cartService.getTotalAmount();
  }
  
  // Remove cart items
  public removeItem(item: CartItem) {    
    this.cartService.removeFromCart(item);
    this.cartItems = this.cartService.getItems();
    this.cartItems.subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems);
    setTimeout(()=>{
      this.fnShippingPrice();
    }, 500);
    // console.log("Shopping Cart Items ---> ",this.shoppingCartItems);
  }

}
