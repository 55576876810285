import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

import { AboutUsComponent } from './about-us/about-us.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { SearchComponent } from './search/search.component';
import { CartComponent } from './cart/cart.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ContactComponent } from './contact/contact.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { CompareComponent } from './compare/compare.component';
import { OrderSuccessComponent } from './order-success/order-success.component';
import { FaqComponent } from './faq/faq.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { ProductCareComponent } from './product-care/product-care.component';
import { DeliveryComponent } from './delivery/delivery.component';
import { WarrantyComponent } from './warranty/warranty.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { ReturnsComponent } from './returns/returns.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    SlickCarouselModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NoopAnimationsModule
  ],
  declarations: [
    AboutUsComponent,
    ErrorPageComponent,
    LoginComponent,
    RegisterComponent,
    SearchComponent,
    CartComponent,
    ForgetPasswordComponent,
    ContactComponent,
    CheckoutComponent,
    CompareComponent,
    OrderSuccessComponent,
    FaqComponent,
    ProductCareComponent,
    DeliveryComponent,
    WarrantyComponent,
    TermsConditionsComponent,
    ReturnsComponent
  ]
})
export class PagesModule { }
