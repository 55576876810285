import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { Product } from '../../shared/classes/product';
import { ProductsService } from '../../shared/services/products.service';
// import { PaginationService } from '../../shared/classes/paginate'
import { trigger, transition, style, animate } from "@angular/animations";
import * as _ from 'lodash'
import * as $ from 'jquery';
import { HttpClient } from '@angular/common/http';
import { CommonService, DataTablesResponse } from 'src/app/shared/services/common.service';
import { ScrollTopService } from 'src/app/shared/services/scrolltotop.service';

@Component({
  selector: 'app-collection-no-sidebar',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [  // angular animation
    trigger('Animation', [
      transition('* => fadeOut', [
        style({ opacity: 0.1 }),
        animate(1000, style({ opacity: 0.1 }))
      ]),
      transition('* => fadeIn', [
         style({ opacity: 0.1 }),
         animate(1000, style({ opacity: 0.1 }))
      ])
    ])
  ]
})
export class CategoryComponent implements OnInit {

  private allProduct   :   Product[] = [];
  public  products     :   Product[] = [];
  public  sortByOrder  :   string = '';   // sorting
  public  animation    :   any;   // Animation
  paginate: any = {};

  public categories:any = [
    { id: 51, images: [{id: 1, image: "elbow-thumb.png"},{id: 2, image: ""}], title: "Elbow", description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry....", itemCount: 5, selected: false },
    { id: 52, images: [{id: 1, image: "pads-thumb.png"},{id: 2, image: ""}], title: "Pads", description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry....", itemCount: 3, selected: false },
    { id: 53, images: [{id: 1, image: "gloves-thumb.png"},{id: 2, image: ""}], title: "Gloves", description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry....", itemCount: 7, selected: false },
    { id: 54, images: [{id: 1, image: "thigh-thumb.png"},{id: 2, image: ""}], title: "Thigh", description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry....", itemCount: 5, selected: false },
  ];

  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private productsService: ProductsService, 
    // private paginateService: PaginationService,
    private http: HttpClient,
    private common: CommonService,
    private scrollToTop:ScrollTopService
  ) { 
    this.route.params.subscribe(params => {
      const category = params['category'];
      this.productsService.getProductByCategory(category).subscribe(products => {
          this.allProduct = products
          this.setPage(1);
      }, err=> {
        this.router.navigate(['/error']);
      });
    });
  }

  
  fnGetItems(parent:any = 0) {
    let payload = { session_id: this.common.session_id, isMenu: 1, parent: parent }
    this.http
    .post<DataTablesResponse>(
      this.common.apiURL + 'categories',
      payload, {headers:this.common.headers()}
    ).subscribe(resp => {
      this.categories = resp.data;
    });
  }

  ngOnInit() { 
    this.scrollToTop.setScrollTop();
    this.fnGetItems(this.route.snapshot.params['category']);
  }
 
  // Animation Effect fadeIn
  public fadeIn() {
      this.animation = 'fadeIn';
  }

  // Animation Effect fadeOut
  public fadeOut() {
      this.animation = 'fadeOut';
  }

  // sorting type ASC / DESC / A-Z / Z-A etc.
  public onChangeSorting(val) {
     this.sortByOrder = val;
     this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
  }

  public setPage(page: number) {
        // get paginate object from service
        // this.paginate = this.paginateService.getPager(this.allProduct.length, page);
        // get current page of items
        this.products = this.allProduct.slice(this.paginate.startIndex, this.paginate.endIndex + 1);
    }

}
