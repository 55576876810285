import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PayPalConfig, PayPalEnvironment, PayPalIntegrationType } from 'ngx-paypal';
import { CartItem } from '../../../shared/classes/cart-item';
import { CartService } from '../../../shared/services/cart.service';
import { OrderService } from '../../../shared/services/order.service';
import { Observable, of } from 'rxjs';
import { CommonService, DataTablesResponse } from 'src/app/shared/services/common.service';
import { ScrollTopService } from 'src/app/shared/services/scrolltotop.service';
import { HttpClient } from '@angular/common/http';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
declare var Razorpay: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  
  // form group
  public checkoutForm   :  FormGroup;
  public cartItems      :  Observable<CartItem[]> = of([]);
  public checkOutItems  :  CartItem[] = [];
  public orderDetails   :  any[] = [];
  public amount         :  number;
  public promoCode      :  string;
  public payPalConfig?  :  PayPalConfig;
  public checkOutTotal:Observable<number>;
  // public discount:any = { id:0, discountPer:0, promoCode:"" };
  public buttonLabel:string = "APPLY";
  public gtotal = 0;


  // Form Validator
  constructor(
    public fb: FormBuilder,
    private http: HttpClient,
    public cartService: CartService,
    public orderService: OrderService,
    public commonService: CommonService,
    private scrollToTop:ScrollTopService
  ) {
    this.checkoutForm = this.fb.group({
      // firstName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50), Validators.pattern('[a-zA-Z \'.-]*$')]],
      // lastName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50), Validators.pattern('[a-zA-Z \'.-]*$')]],
      firstName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      lastName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      countryCode: ['', [Validators.required, Validators.pattern('([+]?[0-9]{1,3})')]],
      mobile: ['', [Validators.required, Validators.pattern('[0-9-]+'), Validators.minLength(7), Validators.maxLength(13)]],
      email: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(100), Validators.email]],
      address: ['', [Validators.required, Validators.maxLength(150)]],
      country: ['', [Validators.required, Validators.maxLength(70)]],
      town: ['', [Validators.required, Validators.maxLength(70)]],
      state: ['', [Validators.required, Validators.maxLength(70)]],
      postalCode: ['', [Validators.required]]
    })
  }

  ngOnInit() {
    this.scrollToTop.setScrollTop();
    this.checkoutForm.status;
    this.checkoutForm.patchValue({
      countryCode: this.commonService.country.code,
      country: (this.commonService.country.id != 1001 && this.commonService.country.id != 4)?this.commonService.country.name:''
    })

    this.cartItems = this.cartService.getItems();
    this.cartItems.subscribe(products => this.checkOutItems = products);
    this.getTotal().subscribe(amount => this.amount = amount);
    this.initConfig();
    this.gTotal();
    this.cartService.discount.amount = 0;

  }
  
  
  // Get sub Total
  public getTotal(): Observable<number> {
    if(this.checkOutItems.length > 0){
      let shippingPrice = 0;
      this.checkOutItems.map((item)=>{
        shippingPrice = shippingPrice + item.product.shippingPrice * item.quantity;
      });
      this.cartService.shippingPrice = shippingPrice?shippingPrice:0;

    }
    this.gTotal();
    return this.cartService.getTotalAmount();
  }

  // Get grand Total
  public getGrandTotal(): Observable<number> {
    return this.cartService.getGrandTotalAmount();
  }

  public gTotal() {
    this.getGrandTotal().subscribe(gt=>{
      this.gtotal = gt?gt:0;
      // this.getDiscount().subscribe(d=>{
      //   this.gtotal = gt - d;
      // })
    });
    // return this.cartService.getGrandTotalAmount().pipe(combineLatest(this.cartService.getDiscount()), map(([gt,d]) =>  Math.abs(gt - d))); 
    // return combineLatest(this.getGrandTotal() , this.getDiscount(), (gt, d)=> Math.abs(gt - d));
  }
 
  public fnGetDiscount(){
    if(this.buttonLabel === 'APPLY'){
      let payload = { session_id: this.commonService.session_id, promoCode: this.promoCode }
      this.http
      .post<DataTablesResponse>(
        this.commonService.apiURL + 'promocode',
        payload, {headers:this.commonService.headers()}
      ).subscribe(resp => {
        this.cartService.discount.discountPer = resp.data.discountPer;
        this.cartService.discount.promoCode = resp.data.promoCode;
        this.cartService.discount.amount = 0;
        this.buttonLabel = "REMOVE";
      });
    }else if(this.buttonLabel === 'REMOVE'){
      this.cartService.discount.discountPer = 0;
      this.cartService.discount.promoCode = 0;
      this.cartService.discount.amount = 0;
      this.buttonLabel = "APPLY";
    }

    this.gTotal();
  }

  getDiscount(): Observable<number> {
    return this.cartService.getDiscount();
  }

  // Paypal payment gateway
  private initConfig(): void {
    this.payPalConfig = new PayPalConfig(PayPalIntegrationType.ClientSideREST, PayPalEnvironment.Production, {
      commit: true,
      client: {
        production: 'AedVxnE3L6rYf_Wyc-YppuvKnp4AkDyyp435pVFf6f8t_HY5uGSVZzJ502o9lZLJD9I7xdrWOxr4DV5v'
        // sandbox: 'AYTxtXWQYhLYsAyu4fLMJE4qoX1wA59bCGJTY0Eh_99cnvWp-Axp19_JMSCo8dWHxg9N1TIoT1Pa22gw', // client Id
      },
      button: {
        size:  'medium',    // small | medium | large | responsive
        shape: 'rect',     // pill | rect
        color: 'gold',     // gold | blue | silver | black
        tagline: false
      },
      
      onPaymentComplete: (data, actions) => {
        this.orderService.createOrder(this.checkOutItems, this.checkoutForm.value, data.orderID, this.amount, this.cartService.shippingPrice, this.cartService.discount, this.gtotal);
      },
      onCancel: (data, actions) => {
        console.log('OnCancel');
      },
      onError: (err) => {
        console.log('OnError');
      },
      transactions: [{
        amount: {
          currency: this.commonService.currency,
          total: this.gtotal
        }
      }]
    });
  }

  // Razorpay config
  RazorpayNow( ) { 
    if(!this.checkoutForm.valid) return;
    let that = this;

    // that.orderService.createOrder(that.checkOutItems, that.checkoutForm.value, 'ssaassaass', that.amount, that.cartService.shippingPrice, that.cartService.discount, that.gtotal);
    // return;
    var options = {
      // "key": "rzp_test_n3ZRHgpUhlwoGq",
      "key": "rzp_live_dEmltzlU11ViUw",
      "amount": that.gtotal*100, // 2000 paise = INR 20
      "currency": that.commonService.currency,
      "name": "Armour & Blade",
      "description": "Online Circket Accessory Store",      
      "handler": function (response={razorpay_payment_id:""}){
        // console.log(response);
        that.orderService.createOrder(that.checkOutItems, that.checkoutForm.value, response.razorpay_payment_id, that.amount, that.cartService.shippingPrice, that.cartService.discount, that.gtotal);
      },
      "prefill": {
          "name":  that.checkoutForm.value.firstName + " " + that.checkoutForm.value.lastName,
          "email": that.checkoutForm.value.email,
          "contact": that.checkoutForm.value.countryCode + that.checkoutForm.value.mobile,
     
      },
      "notes": {custom_field: 'Name on the card'},
      "theme": {
          "color": "#2783f3"
      }
  };
  var rzp1 = new Razorpay(options);

 rzp1.open();

// body...
} 
paid()
{alert();}
  
}
