import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { Product } from '../../shared/classes/product';
import { ProductsService } from '../../shared/services/products.service';
import { trigger, transition, style, animate } from "@angular/animations";
import * as _ from 'lodash'
import * as $ from 'jquery';

import { CommonService, DataTablesResponse } from '../../shared/services/common.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ScrollTopService } from 'src/app/shared/services/scrolltotop.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [  // angular animation
    trigger('Animation', [
      transition('* => fadeOut', [
        style({ opacity: 0.1 }),
        animate(1000, style({ opacity: 0.1 }))
      ]),
      transition('* => fadeIn', [
         style({ opacity: 0.1 }),
         animate(1000, style({ opacity: 0.1 }))
      ])
    ])
  ]
})
export class ProductsComponent implements OnInit {

  private allProduct   :   Product[] = [];
  public  products     :   Product[] = [];
  public  sortByOrder  :   string = '';   // sorting
  public  animation    :   any;   // Animation
  paginate: any = {};

  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private productsService: ProductsService,
    private http : HttpClient,
    private commonService : CommonService,
    private scrollToTop:ScrollTopService
  ) { 
    this.route.params.subscribe(params => {
      const category = params['category'];
      // this.productsService.getProductByCategory(category).subscribe(products => {        
      //   this.allProduct = products
      //   this.products = products;
      //   console.log('');
      // })
      this.fnGetItems(category);
   });

  }

  fnGetItems(category_id:any=""){
    let payload:any = { session_id: this.commonService.session_id, country_id: this.commonService.country_id };
    if(category_id != ""){payload.category_id = category_id;}
    this.http
      .post<DataTablesResponse>(
        this.commonService.apiURL + 'products',
        payload, {headers: this.commonService.headers()})
        .subscribe(resp => {
          this.products = resp.data;          
        }, err=> {
          this.router.navigate(['/error']);
        });
  }

  ngOnInit() { 
    this.scrollToTop.setScrollTop();
  }
 
  // Animation Effect fadeIn
  public fadeIn() {
      this.animation = 'fadeIn';
  }

  // Animation Effect fadeOut
  public fadeOut() {
      this.animation = 'fadeOut';
  }

  // sorting type ASC / DESC / A-Z / Z-A etc.
  public onChangeSorting(val) {
     this.sortByOrder = val;
     this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
  }
}
