import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService, DataTablesResponse } from 'src/app/shared/services/common.service';
declare var $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
  @ViewChild('countryPopup') public countryPopup:ElementRef;

  constructor(
    private http: HttpClient,
    private router: Router,
    public commonService: CommonService
  ) {
   }
   
   dropdownList: Boolean;
  public activeNav:any;

  // private menuItems = [
  //   { id: 1, title: "Blades", link: "custom-product" },
  //   { id: 2, title: "Armour", link: "category" },
  //   { id: 3, title: "Kit Bags", link: "" },
  //   { id: 4, title: "Apparel", link: "" },
  //   { id: 5, title: "Accessories", link: "" },
  //   { id: 6, title: "Contact", link: "" }
  // ];
  public menuItems:any = [];
  public country: any = this.commonService.country;

  fnReset(): void{  }

  fnActive(nav){
    this.activeNav = nav;    
  }

  fnGetMemu() {
    if(this.menuItems.length === 0){
      let payload = { session_id: this.commonService.session_id, isMenu: 1 }
      this.http
      .post<DataTablesResponse>(
        this.commonService.apiURL + 'topmenu',
        payload, {headers:this.commonService.headers()}
      ).subscribe(resp => {
        // console.log(resp);
        // resp.data.push({id: "", name: "Contact Us", parent: 0, route: "contact-us"});
        // this.commonService.menuItems = resp.data;
        // this.menuItems = this.menuItems;
        this.menuItems = this.commonService.menuItems = [...resp.data, ...[{id: "stand", name: "|", parent: 0, route: "#"}, {id: "", name: "Expert Advice", parent: 0, route: "expert-advice"}]];
        // this.menuItems = this.commonService.menuItems = [...resp.data, ...[{id: "", name: "Expert Advice", parent: 0, route: "expert-advice"}]];
      });
    }
  }

  ngOnInit() {
    this.fnGetCountry();    
    
    this.menuItems = this.commonService.menuItems;
    if(this.menuItems.length == 0){
      //console.log("Menu: ", this.menuItems);
      this.fnGetMemu();
    }
  }

  
  fnGetCountry() {
    let payload = { session_id: this.commonService.session_id }
    this.http
    .post<DataTablesResponse>(
      this.commonService.apiURL + 'countries',
      payload, {headers:this.commonService.headers()}
    ).subscribe(resp => {
      resp.data.countries.push({"id":1001,"flag": "007-worldwide.png","code":"","name":"Rest of the world","currency":"USD","sortOrder":"500","isActive":"1","createdBy":"1","createdOn":"2019-01-08 00:00:00","updatedBy":"0","updatedOn":"2019-01-08 13:10:25","creator":"Naveen","createdDate":"08/01/2019"});
      this.commonService.countries = JSON.parse(JSON.stringify(resp.data.countries));
      this.commonService.baseCurrency = JSON.parse(JSON.stringify(resp.data.base.baseCurrency));
    });
  }

  showDropdownList() {
    const box = document.getElementById('submenulist');
    if(this.dropdownList){
      box.classList.add('display');
      this.dropdownList = false;
    } else {
      box.classList.remove('display');
      this.dropdownList = true;
    }
  }

}


