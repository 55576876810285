import { Component, OnInit } from '@angular/core';
import { CommonService, DataTablesResponse } from 'src/app/shared/services/common.service';
import { ProductsService } from 'src/app/shared/services/products.service';
import { HttpClient } from '@angular/common/http';
import { httpFactory } from '@angular/http/src/http_module';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent implements OnInit {

  constructor(
    public commonService: CommonService,
    public productsService: ProductsService,
    private http: HttpClient
  ) { }
  public countries:any = [];
  public categories: any = [];
  public products: any = [];
  public shippingPrices:any = [];
  public filter: any = {};
  public loading: boolean = false;

  fnGetCountries(){
    let payload: any = { session_id: this.commonService.session_id };
    this.http
    .post<DataTablesResponse>(
      this.commonService.apiURL + 'countries',
      payload, {headers:this.commonService.headers()}
    ).subscribe(resp => {
      console.log(resp.data);
      this.countries = resp.data.countries;
    });
  }

  fnGetCategories(){
    let payload: any = { session_id: this.commonService.session_id };
    this.http
    .post<DataTablesResponse>(
      this.commonService.apiURL + 'categories',
      payload, {headers:this.commonService.headers()}
    ).subscribe(resp => {
      this.categories = resp.data;
    });
  }
  fnGetProducts(category_id: any){
    let payload: any = { session_id: this.commonService.session_id, category_id: category_id };
    this.http
    .post<DataTablesResponse>(
      this.commonService.apiURL + 'products',
      payload, {headers:this.commonService.headers()}
    ).subscribe(resp => {
      this.products = resp.data;
    });
  }

  fnGetShippingPrices(){
    this.loading = true;
    let payload: any = this.filter;
    payload.session_id = this.commonService.session_id;
    this.http
    .post<DataTablesResponse>(
      this.commonService.apiURL + 'shippingprices',
      payload, {headers:this.commonService.headers()}
    ).subscribe(resp => {
      this.shippingPrices = resp.data;
      setTimeout(()=>{
        this.loading = false;
      }, 100);
    }, error=>{
      setTimeout(()=>{
        this.loading = false;
      }, 100);
    });
  }

  ngOnInit() {
    this.filter.country_id = this.commonService.country.id;
    this.filter.category_id = "";
    this.filter.product_id = "";
    // this.fnGetShippingPrices();
    this.fnGetCountries();
    this.fnGetCategories();
    setTimeout(()=>{
      this.fnGetShippingPrices();
    }, 300);
  }

}
