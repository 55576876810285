import { Component, OnInit, Input, HostListener, SimpleChange } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

declare var $:any;

@Component({
  selector: 'app-zoom-popup',
  templateUrl: './zoom-popup.component.html',
  styleUrls: ['./zoom-popup.component.scss']
})
export class ZoomPopupComponent implements OnInit {

  @Input() images:any=[];
  @Input() name:string="";
  @Input() imgPath:string="";

  public showMyElement: any;

  constructor(private sanitizer: DomSanitizer) { }

  public screenWidth;
  public slideRightNavConfig;
  public popupHTML;
  public loading:boolean = false;
  public image:string = "";
  public activeSlide:number = 0;

  ngOnInit() {}

  // ngOnChanges(changes: SimpleChange){       
  //   for (let propName in changes) {  
  //     let change = changes[propName];
  //     console.log(change);
  //     if ( propName == 'images' && change && !change.isFirstChange() ) {
  //       this.images = change;
  //     }else{
  //       if ( propName == 'imgPath' && change && !change.isFirstChange() ) {
  //         this.imgPath = change;
  //       }
  //     }
  //   }
  // }

  show(images=""){
    if(images){this.images = images;}
    // if(this.imgPath){
    //   this.images = this.images.map((img)=>{
    //     return this.imgPath + img;
    //   })
    // }
    console.log(images);
    if(this.images && this.images.length > 0){
      this.fnActive(0);
    }
    this.loading = true;
    $('#zoomImagePopup').modal('show');
    setTimeout(()=>{
      this.loading = false;
    },1000);
  }

  hide(){
    setTimeout(()=>{
      $('#zoomImagePopup').modal('hide');
    },100)
  }

  fnActive(index){
    this.activeSlide = index;
    this.image = "";
    setTimeout(()=>{
      this.image = this.images[index];
    },30)    
  }

  fnNext(){
    if(this.activeSlide < (this.images.length-1)){
      this.fnActive(this.activeSlide + 1);
    }    
  }

  fnPre(){
    if(this.activeSlide > 0){
      this.fnActive(this.activeSlide - 1);
    }    
  }

}
