import { Component, ViewEncapsulation, Inject, HostListener, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { WINDOW } from '../services/windows.service';
import { CommonService } from '../services/common.service';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window,
    public commonService: CommonService,
    private router: Router,
  ) { }

  
  ngOnInit() {    
  	$.getScript('assets/js/script.js');
    this.smartMenu();
  }
  
  smartMenu() {
    $('#main-menu').smartmenus({
        subMenusSubOffsetX: 1,
        subMenusSubOffsetY: -8
      });
    $('#sub-menu').smartmenus({
      subMenusSubOffsetX: 1,
      subMenusSubOffsetY: -8
    })
  }


  fnChangeLocation(){
    this.commonService.currencyPopup = true;
    setTimeout(() => {
      this.router.navigate(['/home']);
      this.fnOpenCountryPopup();
    }, 50);        
  }

  fnOpenCountryPopup(){
    $('#countryPopup').modal('show');
  }
  
  fnCloseCountryPopup(){
    this.commonService.currencyPopup = false;
    setTimeout(()=>{
      $('#countryPopup').modal('hide');
    },50)
  }


  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = this.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
    this.document.getElementById("sticky").classList.add('pt-0');  
    if (number >= 100) { 
        this.document.getElementById("sticky").classList.add('pt-0');
      } else {
        this.document.getElementById("sticky").classList.remove('pt-0');
      }
  }

}
