import { Injectable } from '@angular/core';
import { Product } from '../classes/product';
import { CartItem } from '../classes/cart-item';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class CartService {
  
  // Array
  public cartItems  :  BehaviorSubject<CartItem[]> = new BehaviorSubject(JSON.parse(sessionStorage.getItem('armourCart'))?JSON.parse(sessionStorage.getItem('armourCart')):[]);
  // public cartItems  :  BehaviorSubject<CartItem[]> = new BehaviorSubject([]);
  public product    :  CartItem[] = [];
  public shippingPrice: number = 0;
  public shippingPriceObj: any = {};
  public discount:any = { id:0, discountPer:0, promoCode:"", amount:0 };
  
  constructor(
    private toastrService: ToastrService
  ) { 
      this.cartItems.subscribe(products => {
        this.product = products;
      });
  }
  
  
  // Get Products
  public getItems(): Observable<CartItem[]> {
    return this.cartItems.asObservable();
  }
 
  // If item is aleready added
  public hasItem(product: Product): boolean {
    const item = this.product.find(item => item.product.id === product.id);
    return item !== undefined;
  }
  
  // Add to cart
  public addToCart(product: Product, quantity: number, page: string = ""): CartItem | boolean {
    var item: CartItem | boolean = false;
    if (quantity === 0) return false;
    if (page != "blades" && this.hasItem(product)) {
      item = this.product.filter(item => item.product.id === product.id)[0];
      const index = this.product.indexOf(item);
      let qty = this.product[index].quantity + quantity;
      let stock = this.product[index].product.stock;
      if(stock < qty) {
         return false
      } else {
         this.product[index].quantity = qty;
      }
    } else {
      if(product.stock < quantity) {
         return false
      } else {  
        item = {
          product: product,
          quantity: quantity
        };
        this.product.push(item);
      }
    }

    sessionStorage.setItem('armourCart', JSON.stringify(this.product));
    return item;
  }
  
  // Removed in cart
  public removeFromCart(item: CartItem) {
    if (item === undefined) { return; }
      const index = this.product.indexOf(item);
      this.product.splice(index, 1);
      sessionStorage.setItem('armourCart', JSON.stringify(this.product));
  }

  // Removed in cart
  public clearCart() {
    this.cartItems.subscribe(products => {
      for(let p of products){
        this.removeFromCart(p);
      }
    });
    // console.log(this.product);
    if(this.product.length > 0){this.clearCart();}
  }
  
  // Total amount 
  public getTotalAmount(): Observable<number> {    
    return this.cartItems.pipe(map((item: CartItem[], index) => {
      // console.log(item);
      this.shippingPriceObj = {};
      if(!this.shippingPriceObj.shippingPrice && item[0]){this.shippingPriceObj = item[0].product;}
      return item.reduce((prev, curr: CartItem) => {
        if(curr.product.shippingPrice > this.shippingPriceObj.shippingPrice){
          this.shippingPriceObj = curr.product;
          // item[0].product['spPrice'] = curr.product.shippingPrice;
        }
        // console.log(this.shippingPriceObj);
        if(curr.product.price === null) { curr.product.price = 0; }
        if(curr.quantity === null) { curr.quantity = 0; }
        let t = prev + parseFloat(curr.product.price.toString()) * parseInt(curr.quantity.toString());
        this.discount.amount = this.discount.amount?Math.floor((t*this.discount.discountPer) / 100):0;
        //t = t - this.discount.amount;
        // console.log(t);
        return t;
      }, 0);
    }));
  }

  // Total Grand amount 
  public getGrandTotalAmount(): Observable<number> {
    this.shippingPrice = 0;
    return this.cartItems.pipe(map((item: CartItem[]) => {
      return item.reduce((prev, curr: CartItem) => {        
        let gt;
        curr.product.shippingPrice = curr.product.shippingPrice?curr.product.shippingPrice:0;
        curr.product.shippingAddOnPrice = curr.product.shippingAddOnPrice?curr.product.shippingAddOnPrice:0;

        if(this.shippingPriceObj.id === curr.product.id){          
          this.shippingPrice = parseFloat(this.shippingPrice.toString()) + parseFloat(curr.product.shippingPrice.toString());
          gt = prev + curr.product.price * curr.quantity + parseFloat(curr.product.shippingPrice.toString());
          if(curr.quantity > 1){
            this.shippingPrice = parseFloat(this.shippingPrice.toString()) + parseFloat(curr.product.shippingAddOnPrice.toString()) * (curr.quantity - 1);
            gt = gt + (parseFloat(curr.product.shippingAddOnPrice.toString()) * (curr.quantity - 1));
          }
          // console.log(curr.product);
        }else{
          this.shippingPrice = parseFloat(this.shippingPrice.toString()) + parseFloat(curr.product.shippingAddOnPrice.toString()) * curr.quantity;
          gt = prev + (curr.product.price * curr.quantity) + (parseFloat(curr.product.shippingAddOnPrice.toString()) * curr.quantity);
        }
        if(!this.shippingPrice){this.shippingPrice = 0;}
        // console.log(curr.product);
        // gt = gt - this.discount.amount;
        return gt;
      }, 0);
    }));
  }

  // Get Discount 
  public getDiscount(): Observable<number> {
    return this.discount.amount;
  }

}