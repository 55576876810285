import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { FormsModule } from '@angular/forms';
// Services
import { WINDOW_PROVIDERS } from "./services/windows.service";
import { ProductsService } from "./services/products.service";
import { CartService } from "./services/cart.service";
import { OrderService } from "./services/order.service";
// Pipes
import { OrderByPipe } from './pipes/order-by.pipe';
// components
import { HeaderComponent } from './header/header.component';
import { NavbarComponent } from './header/widgets/navbar/navbar.component';
import { SettingsComponent } from './header/widgets/settings/settings.component';
import { FooterComponent } from './footer/footer.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DeferLoadDirective } from './directives/defer-load.directive';


@NgModule({
  exports: [
    CommonModule,
    HeaderComponent,
    FooterComponent,
    OrderByPipe,
    DeferLoadDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    SlickCarouselModule,
    BsDropdownModule,
    FormsModule
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    OrderByPipe,
    NavbarComponent,
    SettingsComponent,
    DeferLoadDirective
    // QuickViewComponent
  ],
  providers: [
    WINDOW_PROVIDERS,
    ProductsService,
    CartService,
    OrderService
  ]
})
export class SharedModule { }
