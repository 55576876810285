import { ChangeDetectionStrategy, Component, Input, Inject, OnInit } from '@angular/core';
import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Product } from '../../../shared/classes/product';
import { CartItem } from '../../../shared/classes/cart-item';
import { ProductsService } from '../../../shared/services/products.service';
import { CartService } from '../../../shared/services/cart.service';
import { Observable, of } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';


@Component({
  selector: 'app-single-product',
  templateUrl: './single-product.component.html',
  styleUrls: ['./single-product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SingleProductComponent implements OnInit {
  
  @Input() product : Product;

  public showMyElement: any;

  public cartItems          :   Observable<CartItem[]> = of([]);
  public shoppingCartItems  :   CartItem[] = [];
  public selectedItem       :   any = '';
  public variantImage       :   any = "";

  constructor(
    private router: Router,
    public toastrService: ToastrService, 
    public productsService: ProductsService, 
    public cartService: CartService,
    public commonService: CommonService
  ) {  }

  ngOnInit() { 
    this.cartItems = this.cartService.getItems();
    this.cartItems.subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems);
  }

  // If item is aleready added
  public hasItem(product: Product): boolean {
    const item = this.shoppingCartItems.find(item => item.product.id === product.id);
    return item !== undefined;
  }

  // Add to cart
  public addToCart(product: Product,  quantity: number = 1) {
    if (quantity == 0) return false;
    if (this.hasItem(product)) {
        var item = this.shoppingCartItems.filter(item => item.product.id === product.id)[0];
        const index = this.shoppingCartItems.indexOf(item);
        let qty = this.shoppingCartItems[index].quantity + quantity;
        let stock = this.shoppingCartItems[index].product.stock;
        if(stock < qty) {
           this.toastrService.error('You can not add more items than available. In stock '+ stock +' items.'); // toasr services
        } else {
           this.cartService.addToCart(product,quantity);
          //  this.toastrService.success('This product has been added to cart.','', { timeOut: 1000 });
        }
    } else {
      if(product.stock < quantity) {
        this.toastrService.error('You can not add more items than available. In stock '+ product.stock +' items.'); // toasr services
      } else {
        this.cartService.addToCart(product,quantity);
        // this.toastrService.success('This product has been added to cart.','', { timeOut: 1000 });
      }
    }
  }

  // Add to compare
  public addToCompare(product: Product) {
     this.productsService.addToCompare(product);
  }

}
