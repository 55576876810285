import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '../shared/services/common.service';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss']
})
export class FeedbackFormComponent implements OnInit {

  public questions: Array<any> = [
    { id: 20, type: "textbox", placeholder: "What are the range of shots that you consider to be your strengths?", answer: "", title: "", question: "" },
    { id: 30, type: "textbox", placeholder: "Which country and what sort of wickets will you be playing most of your cricket on?", answer: "", title: "", question: "" },
    {
      id: 40, type: "radio", placeholder: "", answer: "", title: "In which category of batsmen would you place yourself?", question: "In which category of batsmen would you place yourself?", options: [
        "Single and Doubles with Odd Boundries",
        "Free following technical strokeplay",
        "Power-Hits",
        "Slogger"
      ]
    },
    {
      id: 50, type: "radio", placeholder: "", answer: "", title: "Where do you like to play most of your deliveries?", question: "Where do you like to play most of your deliveries?", options: [
        "Single and Doubles with Odd Boundries.",
        "Free following technical strokeplay.",
        "Power-Hits"
      ]
    },
    { id: 60, type: "radio", placeholder: "", answer: "", title: "Out of 10 shots played, how many are you likely to hit in the air?", question: "Out of 10 shots played, how many are you likely to hit in the air?", options: [
      "4-6",
      "6-8",
      "8-10"
      ] 
    },
    { id: 70, type: "textarea", placeholder: "What can we help you with?", answer: "", title: "", question: "" },
    // { id: 70, type: "textbox", placeholder: "", answer: "", title: "", question: "Height:" },
    // { id: 80, type: "textbox", placeholder: "", answer: "", title: "", question: "Weight:" },
    // { id: 90, type: "textbox", placeholder: "", answer: "", title: "", question: "Age:" }
  ];

  public frm: any = {};

  public submitted: boolean = false;
  public error: any = {};
  public showMessage: boolean = false;
  public loading: boolean = false;

  constructor(
    private http: HttpClient,
    public common: CommonService,
    private fb: FormBuilder
  ) {
    this.frm['fullName'] = "";
    this.frm['email'] = "";
    this.frm['feedback'] = JSON.parse(JSON.stringify(this.questions));
  }

  ngOnInit() {    
    this.scrollToError('.page-heading');
  }

  fnSubmit() {
    if(!this.frm['fullName']) {
      this.error = {key: "fullname", message: "Please enter your full name"}
      this.scrollToError('.ng-name-error');
    } else if(!this.frm['email']) {
      this.error = {key: "email", message: "Please enter your email address"}
      this.scrollToError('.ng-email-error');
    } else if(this.frm['email'] != "" && !this.isValid(this.frm['email'])) {
      this.error = {key: "email", message: "Please enter a valid email address"}
      this.scrollToError('.ng-email-error');
    } else {
      let payload: any = {
        fullName: this.frm.fullName,
        email: this.frm.email
      };
      payload['feedback'] = this.frm.feedback.map(q => {
        return { id: q.id, question: q.question, options: q.options, answer: q.answer };
      });
  
      payload.feedback = JSON.stringify(payload.feedback);
      console.log(payload);
  
      this.http.post<any>(
        this.common.apiURL + 'feedback',
        payload, { headers: this.common.headers() }
      ).subscribe(resp => {
        this.ngOnInit();
        this.submitted = false;
        this.showMessage = true;
        this.frm.fullName = "";
        this.frm.email = "";
        this.frm.feedback = JSON.parse(JSON.stringify(this.questions));
        setTimeout(() => {
          this.loading = false;
        }, 300);
      }, error => {
        setTimeout(() => {
          this.loading = false;
        }, 300);
        this.scrollToError('.ng-success');
        console.log(error);
      });

    }
    
  }

  private isValid(email: string): boolean {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
  scrollToError(elm: string): void {
    const firstElementWithError = document.querySelector(elm);
    this.scrollTo(firstElementWithError);
  }

}
