import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public serverPath = environment.apiUrl;

  public categoryImagePath = this.serverPath + "resbucket/category/";  
  public productImagePath = this.serverPath + "resbucket/product/";

  public apiURL = this.serverPath + "backend/";

  public session_id: string = 'guest';
  public countries: any = [];
  public country: any = {};
  public country_id: number = 2;
  public currency: string = 'INR';
  public baseCurrency: string = 'INR';
  public flag: string = ',"001-india.png';

  public currencyPopup: boolean = true;

  public headers(headers:any={}){
    let token = localStorage.getItem('token');
    if(headers){
      return new HttpHeaders(headers);
    }else{
      if (token == null) {
        headers = new HttpHeaders({'Content-Type': 'application/json'});
      } else {
        headers = new HttpHeaders({
          // 'X-Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json'
        });
      }
      return headers;
    }
  };

  constructor(
    private http: HttpClient
  ) {
    let cntry:any = localStorage.getItem('country');
    // console.log("Country: ",cntry);
    this.country = cntry?JSON.parse(cntry):{};
    this.currencyPopup = this.country.id?this.fnCurrencyPopup():true;
    this.country_id = this.country.id?this.country.id:2;
    this.currency = this.country.currency?this.country.currency:'INR';
    this.flag = this.country.flag?this.country.flag:'pp1-india.png';
  }

  fnCurrencyPopup() {
    let popup: boolean = true;
    if(localStorage.getItem('currencyPopup') === "false"){
      popup = false;
    }else{
      popup = true;
    }

    return popup;
  }

  public menuItems = [];

  // fnGetMemu() {
  //   if(this.menuItems.length === 0){
  //     let payload = { session_id: 'gust', isMenu: 1 }
  //     this.http
  //     .post<DataTablesResponse>(
  //       this.apiURL + 'topmenu',
  //       payload, {headers:this.headers()}
  //     ).subscribe(resp => {
  //     this.menuItems = resp.data;
  //     });
  //   }
  //   return this.menuItems;
  // }
}

// Response format
export class DataTablesResponse {
  data: any;
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

